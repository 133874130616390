import React, { useState, useEffect } from "react";
import "./home.css";
import bgPhoto from "../../123.jpg";
import { Link } from "react-router-dom"; // Import Link

function Home() {
  const [randomPoems, setRandomPoems] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const endpoints = [
        "/data/people/posts.js",
        "/data/places/posts.js",
        "/data/voices/posts.js",
        "/data/faces/posts.js",
        "/data/littlelightbulbmoments/posts.js",
        "/data/colourmegreen/posts.js",
      ];
      try {
        const allPosts = [];

        await Promise.all(
          endpoints.map(async (path) => {
            const response = await fetch(path);
            if (!response.ok) {
              throw new Error(
                `HTTP error! status: ${response.status} at ${path}`
              );
            }
            const jsonData = await response.json();
            allPosts.push(
              ...jsonData.map((post) => ({
                ...post,
                route: path.split("/")[2],
              }))
            ); //add route info
          })
        );

        const shuffledPosts = allPosts.sort(() => 0.5 - Math.random()); // Shuffle all posts
        const selectedPosts = shuffledPosts.slice(0, 4); // Select first 4

        setRandomPoems(selectedPosts);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  console.log(randomPoems);
  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img src={bgPhoto} style={{ maxWidth: "60%" }} />
      </div>
      <div className="poem-grid">
        {randomPoems.map((poem, index) => {
          const words = poem.post.split(/\s+/);
          const truncatedPost = words.slice(0, 75).join(" ");

          const postLink = `${poem.route}#${poem.name}`; // Use the embedded route
          return (
            <Link
              className="poem-link"
              to={postLink}
              style={{ textDecoration: "none" }}>
              <div key={index} className="poem-card">
                {poem.photo && (
                  <div>
                    <img
                      src={"/data/" + poem.route + "/assets/" + poem.photo}
                      alt={poem.name}
                      className="poem-image" // Add class for styling
                    />
                  </div>
                )}
                <div>
                  <h3>
                    {poem.name} {poem.tags && <>({poem.tags})</>}
                  </h3>
                  <p>{truncatedPost}...</p>
                </div>
              </div>
            </Link>
            

          );
        })}
      </div>
    </>
  );
}

export default Home;
