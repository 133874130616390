import React, { useState, useEffect, useRef, useCallback } from "react";
import "../pages.css";
import { useLocation } from "react-router-dom";

// import { posts as llb_posts } from "../../../public/data/littlelightbulbmoments/posts";
function Template(props) {
  const [posts, setposts] = useState([]);
  const [selectedTag, setSelectedTag] = useState(null);
  const [uniqueTags, setuniqueTags] = useState([]);

  const location = useLocation();
  const poemRefs = useRef({});

  const [filteredPosts, setfilteredPosts] = useState([]);

  const setPoemRef = useCallback((element, name) => {
    console.log("element", element, "name", name);
    poemRefs.current[name] = element;
  }, []);

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  useEffect(() => {
    async function scrollToHash() {
      // Make this function async
      if (location.hash) {
        const elementId = decodeURIComponent(location.hash.substring(1)).replaceAll(" ", "-");
        console.log("Elenenbt", elementId)
        // Wait for a small amount of time (adjust as needed):
        await sleep(350); // Wait 100 milliseconds

        if (poemRefs.current[elementId]) {
          poemRefs.current[elementId].scrollIntoView({ behavior: "smooth" });
        }
      }
    }

    scrollToHash(); // Call the async function
  }, [location]);

  useEffect(() => {
    fetch("/data/" + props.component + "/posts.js") // Relative to the public directory in the build
      .then((response) => {
        if (!response.ok) {
          // Check if the response was successful
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json(); // Parse the response body as JSON
      })
      .then((jsonData) => {
        setposts(jsonData);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        // Handle the error, e.g., display an error message to the user
      });
  }, [props.component]);

  // Get unique tags
  useEffect(() => {
    posts !== undefined &&
      setuniqueTags([...new Set(posts.flatMap((post) => post.tags || []))]);
  }, [posts]);

  useEffect(() => {
    selectedTag
      ? setfilteredPosts(
          posts.filter((post) => (post.tags || []).includes(selectedTag))
        )
      : setfilteredPosts(posts);
  }, [selectedTag, posts]);

  // const uniqueTags = [...new Set(posts.flatMap((post) => post.tags || []))];

  const handleTagClick = (tag) => {
    setSelectedTag(tag);
  };

  return (
    <div className="container">
      <header>
        {props.component == "people" && <h2>People I've Met</h2>}
        {props.component == "places" && <h2>Places I've Been</h2>}
        {props.component == "voices" && <h2>Voices I've Heard</h2>}
        {props.component == "faces" && <h2>Faces I've Seen</h2>}
        {props.component == "littlelightbulbmoments" && (
          <h2>Little Light Bulb Moments</h2>
        )}
        {props.component == "colour-me-green" && <h2>Colour Me Green</h2>}
      </header>

      <div className="content">
        <div className="sidebar">
          {uniqueTags.length > 0 && (
            <>
              <h3>TAGS</h3>
              <ul>
                <li
                  key="all"
                  onClick={() => handleTagClick(null)}
                  className={selectedTag === null ? "selected" : ""} // Add class based on selection
                >
                  <a href="#">All</a>
                </li>
                {uniqueTags.map((tag) => (
                  <li
                    key={tag}
                    onClick={() => handleTagClick(tag)}
                    className={selectedTag === tag ? "selected" : ""} // Add class based on selection
                  >
                    <a href="#">{tag}</a>
                  </li>
                ))}
              </ul>
              <hr />
            </>
          )}

          {/* <h3>Index</h3> */}
          <ul style={{ textAlign: "left" }}>
            {filteredPosts.map((data) => (
              <li key={data.name}>
                <a style={{ fontSize: "14px" }} href={`#${data.name}`}>
                  {data.name} {data.date !== "" && <>({data.date})</>}
                </a>
              </li>
            ))}
          </ul>
        </div>

        <section className="person-section">
          {filteredPosts?.map((data) => {
            console.log("AA::", data);
            const safeId = data.name.replace(/\s+/g, '-'); // Replace spaces with hyphens

            return (
              <div className="person" id={safeId} key={data.name} ref={(element) => setPoemRef(element, safeId)}>

                {/* Added key here */}
                <h3>{data.name}</h3>
                <p className="date">
                  {data.date !== "" && <>{data.date} </>}
                  {data.date !== "" && data.tags !== "" && <>| </>}
                  {data.tags !== "" && <>{data.tags} </>}
                </p>
                {data.photo && (
                  <img
                    style={{ width: "100%" }}
                    src={"/data/" + props.component + "/assets/" + data.photo}
                  />
                )}
                <p className="description">
                  {data.post.split("\n").map((line, index) => (
                    <p key={index}>{line}</p>
                  ))}
                </p>
                <ul className="tags-list">
                  {Array.isArray(data?.tags) &&
                    data.tags.map((tag) => (
                      <li
                        key={tag}
                        onClick={() => handleTagClick(tag)}
                        className={selectedTag === tag ? "selected" : ""} //Highlight selected tag in post
                      >
                        {tag}
                      </li>
                    ))}
                </ul>
              </div>
            );
          })}
        </section>
      </div>
    </div>
  );
}

export default Template;
