import React, { useState } from "react";
import "./App.css";
import "./NavBar.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  useLocation,
} from "react-router-dom";
import SocialFooter from "./components/SocialFooter";
import Home from "./pages/home/Home";
import Modal from "./Modal";
import profilePhoto from "./rkb_photo.jpg";
import Template from "./pages/template/Template.jsx";

const App = () => {
  const [isModalopen, setModalOpen] = useState(false);
  return (
    <>
    <div style={{"backgroundColor":"aliceblue"}}>
      <Router>
        <div className="container">
          <nav className="navbar">
            <div className="navbar-header" onClick={() => setModalOpen(true)}>
              <a className="title-rkb">
                Ranee Kaur Banerjee
              </a>
            </div>
            <a style={{ fontSize: "13px", marginBottom: "10px" }}>
              Word-painter. Wanderer. Woolgatherer.
            </a>
            <hr style={{ width: "100%" }} />
            <div style={{ width: "100%" }}>
              <ul className="navbar-menu">
                <li style={{ backgroundColor: "#ddheed" }}>
                  <Link className="navbar-item" to={"/"}>
                    HOME
                  </Link>
                </li>
                <li style={{ backgroundColor: "#ddheed" }}>
                  <Link className="navbar-item" to={"/people"}>
                    PEOPLE
                  </Link>
                </li>
                <li>
                  <Link className="navbar-item" to={"/places"}>
                    PLACES
                  </Link>
                </li>
                <li>
                  <Link className="navbar-item" to={"/voices"}>
                    VOICES
                  </Link>
                </li>
                <li>
                  <Link className="navbar-item" to={"/faces"}>
                    FACES
                  </Link>
                </li>
                <li>
                  <Link className="navbar-item" to={"/lightbulbmoments"}>
                    LITTLE <br />
                    <a style={{ background: "yellow" }}>
                      LIGHTBULB
                    </a> <br /> MOMENTS
                  </Link>
                </li>
                <li>
                  <Link className="navbar-item" to={"/colourmegreen"}>
                    COLOUR ME GREEN
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>

        {isModalopen && (
          <Modal onClose={() => setModalOpen(false)}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}>
              <div>
                <p>Dr Ranee Kaur Banerjee</p>
                <p>
                  Words make up most of my world. I'm lucky they also make up
                  most of my livelihood as a professional editor because even
                  cosmos creators need day jobs. All I have ever achieved has
                  been based on my affinity and facility with words, my visceral
                  need to know and learn anything about everything, and the
                  uncontrollable urge to conceive and convey.{" "}
                </p>
              </div>
              <div>
                <img
                  style={{ height: "250px", marginRight: "10px" }}
                  src={profilePhoto}
                />
              </div>
            </div>
          </Modal>
        )}

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/people" element={<Template component={"people"} />} />
          <Route path="/places" element={<Template component={"places"} />} />
          <Route path="/voices" element={<Template component={"voices"} />} />
          <Route path="/faces" element={<Template component={"faces"} />} />
          <Route
            path="/lightbulbmoments"
            element={<Template component={"littlelightbulbmoments"} />}
          />
          <Route
            path="/colourmegreen"
            element={<Template component={"colourmegreen"} />}
          />
        </Routes>
      </Router>
      <SocialFooter />
      </div>
    </>
  );
};

export default App;
