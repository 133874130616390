import React from "react";
import "./socialfooter.css"

function SocialFooter() {
  return (
    <>


      <footer>
        <div style={{scale:"0.5"}} class="rounded-social-buttons">
          <a
            class="social-button facebook"
            href="https://www.facebook.com/ranee.kaur.banerjee"
            target="_blank">
            <i class="fab fa-facebook-f"></i>
          </a>
          <a
            class="social-button twitter"
            href="https://x.com/raneekb"
            target="_blank">
            <i class="fab fa-twitter"></i>
          </a>
          <a
            class="social-button linkedin"
            href="https://www.linkedin.com/in/raneekaurbanerjee/"
            target="_blank">
            <i class="fab fa-linkedin"></i>
          </a>

          {/* <a
            class="social-button youtube"
            href="https://www.youtube.com/"
            target="_blank">
            <i class="fab fa-youtube"></i>
          </a> */}
          <a
            class="social-button instagram"
            href="https://www.instagram.com/raneekb/"
            target="_blank">
            <i class="fab fa-instagram"></i>
          </a>
          <a
            class="social-button instagram"
            href="https://www.instagram.com/raneekb/"
            target="_blank">
            <i class="fab fa-threads"></i>
          </a>
        </div>
      </footer>
    </>
  );
}

export default SocialFooter;
